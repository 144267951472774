export default class Gate{

    constructor(user){
        this.user = user;
    }

    isSuperAdmin(){
        return this.user.type === 'super_admin';
    }

    isSchoolAdmin(){
        return this.user.type === 'school_admin';
    }

    isAdmin(){
        if(this.user.type === 'super_admin' || this.user.type === 'school_admin'){
            return true;
        }
    }

    isUser(){
        return this.user.type === 'user';
    }
    
    isSuperAdminOrSchoolAdminOrUser(){
        if(this.user.type === 'user' || this.user.type === 'super_admin' || this.user.type === 'school_admin'){
            return true;
        }
    }
}

