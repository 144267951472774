export default [
    //{ path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/dashboard', component: require('./components/Profile.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/organizations', component: require('./components/Organizations.vue').default },
    { path: '/first_level_divisions', component: require('./components/First_level_divisions.vue').default },
    { path: '/second_level_divisions', component: require('./components/Second_level_divisions.vue').default },
    { path: '/seasons', component: require('./components/Seasons.vue').default },
    { path: '/customers', component: require('./components/Customers.vue').default },
    { path: '/customers_barcodes', component: require('./components/Customers.vue').default },
    { path: '/customers_promotions', component: require('./components/Customers.vue').default },
    { path: '/customers_deactivations', component: require('./components/Customers.vue').default },
    { path: '/meals_permissions', component: require('./components/Customers.vue').default },
    { path: '/tuck_shop_balances', component: require('./components/Customers.vue').default },
    { path: '/meals', component: require('./components/Meals.vue').default },
    { path: '/meals_between_dates', component: require('./components/MealsBetweenDates.vue').default },
    { path: '/serve_meals', component: require('./components/ServeMeals.vue').default },
    { path: '/sell_products', component: require('./components/SellProducts.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
