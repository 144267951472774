<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">
                                {{ organization_type.customer_name }} {{$route.path == '/customers' ? 'List' : $route.path == '/customers_barcodes' ? 'Barcodes Printing' : $route.path == '/customers_promotions' ? 'Promotions' : $route.path == '/customers_deactivations' ? '(De)Activations' : $route.path == '/meals_permissions' ? 'Meals Permissions' : 'Tuck Shop Balances'}}
                            </h3>

                            <div class="card-tools">                                
                                <form class="form-inline btn btn-sm">
                                    <select id="pagination" v-model="form3.items_per_page" @change="getResults">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="250">250</option>
                                    </select>
                                </form>
                                <button  v-if="$route.path == '/customers'"
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="newModal"
                                >
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>

                                <button v-if="$route.path == '/customers_barcodes'"
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="printAllModal"
                                >
                                    <i class="fa fa-barcode"></i>
                                    Print All Barcodes
                                </button>

                                <button v-if="$route.path == '/customers_promotions'"
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="promoteAllModal"
                                >
                                    <i class="fa fa-arrow-up"></i>
                                    Promote All
                                </button>

                                <button v-if="$route.path == '/customers_deactivations'"
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="deactivateAllModal"
                                >
                                    <i class="fa fa-times red"></i>
                                    (De)Activate All
                                </button>

                                <button v-if="$route.path == '/meals_permissions'"
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="applyPermissionToAllModal"
                                >
                                    <i class="fa fa-edit"></i>
                                    Apply Perm. To All
                                </button>
                                
                                <button v-if="$route.path == '/tuck_shop_balances'"
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="performTransactionOnAllModal"
                                >
                                    <i class="fa fa-edit"></i>
                                    Perform Tx. On All
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th style="width: 10%">ID</th>
                                        <th style="width: 15%">Name</th>
                                        <th style="width: 10%">
                                            {{
                                                organization_type.customer_name
                                            }}
                                            No.
                                        </th>
                                        <th v-if="$route.path != '/meals_permissions'" style="width: 10%">ID No.</th>
                                        <th style="width: 15%">
                                            {{
                                                organization_type.first_level_division_name
                                            }}
                                        </th>
                                        <th v-if="$route.path != '/meals_permissions'" style="width: 10%">
                                            {{
                                                organization_type.second_level_division_name
                                            }}
                                        </th>
                                        <th v-if="$route.path == '/meals_permissions'" style="width: 40%">Meal Permissions</th>
                                        <th v-if="$route.path == '/tuck_shop_balances'" style="width: 20%">Tuck Shop Bal</th>
                                        <th v-if="$route.path != '/meals_permissions' && $route.path != '/tuck_shop_balances'" style="width: 10%">Active?</th>
                                        <th v-if="$route.path != '/meals_permissions' && $route.path != '/tuck_shop_balances'" style="width: 15%">Created</th>
                                        <th style="width: 10%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        
                                            <td>
                                                <input v-model="form2.id" type="number" class="form-control form-control-sm" placeholder="ID Search"/>
                                            </td>
                                            <td>
                                                <input v-model="form2.name" type="text" class="form-control form-control-sm" placeholder="Name Search"/>
                                            </td>
                                            <td>
                                                <input v-model="form2.customer_no" type="text" class="form-control form-control-sm" :placeholder="organization_type.customer_name + ' No. Search'"/>
                                            </td>
                                            <td v-if="$route.path != '/meals_permissions'">
                                                <input v-model="form2.id_no" type="text" class="form-control form-control-sm" placeholder="ID No. Search"/>
                                            </td>
                                            <td>
                                                <select v-model="form2.first_level_division_id" class="form-control form-control-sm">
                                                    <option value="0" disabled>Select {{organization_type.first_level_division_name}}</option>
                                                    <option v-for="(name, index) in first_level_divisions" :key="index" :value="index" :selected="index == form2.first_level_division_id">
                                                        {{ name }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td v-if="$route.path != '/meals_permissions'">
                                                <select v-model="form2.second_level_division_id" class="form-control form-control-sm">
                                                    <option value="0" disabled>Select {{organization_type.second_level_division_name}}</option>
                                                    <option v-for="(name, index) in second_level_divisions" :key="index" :value="index" :selected="index == form2.second_level_division_name">
                                                        {{ name }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td v-if="$route.path != '/meals_permissions' && $route.path != '/tuck_shop_balances'">
                                                <select v-model="form2.active" class="form-control form-control-sm">
                                                    <option value="" disabled>Select Active Status</option>
                                                    <option v-for="(name, index) in yes_no" :key="index" :value="index" :selected="index == form2.active">
                                                        {{ name }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td v-if="$route.path == '/meals_permissions' || $route.path == '/tuck_shop_balances'">                                                
                                            </td>
                                            <td v-if="$route.path != '/meals_permissions' && $route.path != '/tuck_shop_balances'" id="search_date_td">
                                                <date-picker v-model="form2.created_at" class="form-control form-control-sm" placeholder="Created Search" :config="options"/>
                                            </td>
                                            <td>
                                                <a
                                                    href="#"
                                                    @click="getResults"
                                                >
                                                    <i class="fa fa-search blue"></i>
                                                </a>
                                                /
                                                <a
                                                    href="#"
                                                    @click="cancelSearch"                                                    
                                                >
                                                    <i class="fa fa-search red"></i>
                                                </a>
                                            </td>
                                    </tr>
                                    <tr
                                        v-for="customer in customers.data"
                                        :key="customer.id"
                                    >
                                        <td>{{ customer.id }}</td>
                                        <td>{{ customer.name }}</td>
                                        <td>{{ customer.customer_no }}</td>
                                        <td v-if="$route.path != '/meals_permissions'">{{ customer.id_no }}</td>
                                        <td>
                                            {{
                                                customer.first_level_division_name
                                            }}
                                        </td>
                                        <td v-if="$route.path != '/meals_permissions'">
                                            {{
                                                customer.second_level_division_name
                                            }}
                                        </td>
                                        <td v-if="$route.path != '/meals_permissions' && $route.path != '/tuck_shop_balances'">
                                            {{
                                                customer.active ? 'Yes' : 'No'
                                            }}
                                        </td>
                                        <td v-if="$route.path == '/meals_permissions' ">
                                            <ul v-if="customer.meals_permissions.length > 0" style="padding: 0;margin-bottom:0;">
                                                <li v-for="mp in customer.meals_permissions" :key="mp.id"><small>{{mp.display_string}}</small></li>
                                            </ul>
                                            <i v-else>All Meals are Allowed</i>
                                        </td>
                                        <td v-if="$route.path == '/tuck_shop_balances'" @click="showTransactionsModal(customer)" title="click to show all transactions">
                                            {{
                                                Number(customer.tuck_shop_balance).toLocaleString()
                                            }}
                                        </td>
                                        <!--<td :inner-html.prop="user.email_verified_at | yesno"></td>-->
                                        <td v-if="$route.path != '/meals_permissions' && $route.path != '/tuck_shop_balances'">
                                            {{
                                                customer.created_at
                                                    | moment(
                                                        "YYYY-MM-DD HH:MM:ss"
                                                    )
                                            }}
                                        </td>

                                        <td v-if="$route.path == '/customers'">
                                            <a
                                                href="#"
                                                @click="editModal(customer)"
                                            >
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            /
                                            <a
                                                href="#"
                                                @click="
                                                    deleteCustomer(
                                                        customer.id
                                                    )
                                                "
                                            >
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>

                                        <td v-if="$route.path == '/customers_barcodes'">
                                            <a
                                                href="#"
                                                @click="printModal(customer)"
                                            >
                                                <i class="fa fa-barcode blue"></i>
                                            </a>                                            
                                        </td>

                                        <td v-if="$route.path == '/customers_promotions'">
                                            <a
                                                href="#"
                                                @click="promoteModal(customer)"
                                            >
                                                <i class="fa fa-arrow-up blue"></i>
                                            </a>                                            
                                        </td>
                                        <td v-if="$route.path == '/customers_deactivations'">
                                            <a
                                                href="#"
                                                @click="deactivateModal(customer)"
                                            >
                                                <i :class="'fa fa-' + (customer.active ? 'times red' : 'check blue')"></i>
                                            </a>                                            
                                        </td>
                                        <td v-if="$route.path == '/meals_permissions'">
                                            <a
                                                href="#"
                                                @click="applyPermissionModal(customer)"
                                            >
                                                <i :class="'fa fa-edit blue'"></i>
                                            </a>                                            
                                        </td>
                                        <td v-if="$route.path == '/tuck_shop_balances'">
                                            <a
                                                href="#"
                                                @click="performTransactionModal(customer)"
                                            >
                                                <i :class="'fa fa-edit blue'"></i>
                                            </a>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="customers"
                                @pagination-change-page="getResults"
                            ></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>

            <!-- Customer Modal -->
            <div
                class="modal fade"
                id="addNew"
                tabindex="-1"
                role="dialog"
                aria-labelledby="addNew"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">
                                Create New {{organization_type.customer_name}}
                            </h5>
                            <h5 class="modal-title" v-show="editmode && !promotemode && !deactivatemode">
                                Update {{organization_type.customer_name}}'s Info
                            </h5>
                            <h5 class="modal-title" v-show="editmode && promotemode && !deactivatemode">
                                Promote {{organization_type.customer_name}}
                            </h5>
                            <h5 class="modal-title" v-show="editmode && deactivatemode && !promotemode">
                                (De)Activate {{organization_type.customer_name}}
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <!-- <form @submit.prevent="createUser"> -->

                        <form
                            @submit.prevent="
                                editmode ? (promotemode ? promoteCustomer() : (deactivatemode ? deactivateCustomer() : updateCustomer())) : createCustomer()
                            "
                        >
                            <div class="modal-body">
                                <div v-if="!promotemode && !deactivatemode" class="form-group">
                                    <label>Name</label>
                                    <input
                                        v-model="form.name"
                                        type="text"
                                        name="name"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                form.errors.has('name'),
                                        }"
                                    />
                                    <has-error
                                        :form="form"
                                        field="name"
                                    ></has-error>
                                </div>
                                <div v-if="!promotemode && !deactivatemode" class="form-group">
                                    <label
                                        >{{
                                            organization_type.customer_name
                                        }}
                                        No.</label
                                    >
                                    <input
                                        v-model="form.customer_no"
                                        type="text"
                                        name="customer_no"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                form.errors.has('customer_no'),
                                        }"
                                    />
                                    <has-error
                                        :form="form"
                                        field="customer_no"
                                    ></has-error>
                                </div>
                                <div v-if="!promotemode && !deactivatemode" class="form-group">
                                    <label>ID No.</label>
                                    <input
                                        v-model="form.id_no"
                                        type="text"
                                        name="id_no"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                form.errors.has('id_no'),
                                        }"
                                    />
                                    <has-error
                                        :form="form"
                                        field="id_no"
                                    ></has-error>
                                </div>
                                <div v-if="!deactivatemode" class="form-group">
                                    <label>{{
                                        organization_type.first_level_division_name
                                    }}</label>
                                    <select
                                        name="first_level_division_id"
                                        v-model="form.first_level_division_id"
                                        id="first_level_division_id"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': form.errors.has(
                                                'first_level_division_id'
                                            ),
                                        }"
                                    >
                                        <option value="0" disabled>
                                            Select
                                            {{
                                                organization_type.first_level_division_name
                                            }}
                                        </option>
                                        <option
                                            v-for="(
                                                name, index
                                            ) in first_level_divisions"
                                            :key="index"
                                            :value="index"
                                            :selected="
                                                index ==
                                                form.first_level_division_id
                                            "
                                        >
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error
                                        :form="form"
                                        field="first_level_division_id"
                                    ></has-error>
                                </div>
                                <div  v-if="!promotemode && !deactivatemode" class="form-group">
                                    <label>{{
                                        organization_type.second_level_division_name
                                    }}</label>
                                    <select
                                        name="second_level_division_id"
                                        v-model="form.second_level_division_id"
                                        id="second_level_division_id"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': form.errors.has(
                                                'second_level_division_id'
                                            ),
                                        }"
                                    >
                                        <option value="0" disabled>
                                            Select
                                            {{
                                                organization_type.second_level_division_name
                                            }}
                                        </option>
                                        <option
                                            v-for="(
                                                name, index
                                            ) in second_level_divisions"
                                            :key="index"
                                            :value="index"
                                            :selected="
                                                index ==
                                                form.second_level_division_id
                                            "
                                        >
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error
                                        :form="form"
                                        field="second_level_division_id"
                                    ></has-error>
                                </div>

                                <div  v-if="deactivatemode" class="form-group">
                                    <label>Active?</label>
                                    <select
                                        name="active"
                                        v-model="form.active"
                                        id="active"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': form.errors.has(
                                                'active'
                                            ),
                                        }"
                                    >
                                        <option value="" disabled>
                                            Select if Active or Not
                                        </option>
                                        <option
                                            v-for="(
                                                name, index
                                            ) in yes_no"
                                            :key="index"
                                            :value="index"
                                        >
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error
                                        :form="form"
                                        field="active"
                                    ></has-error>
                                </div>

                                <div v-if="!promotemode && !deactivatemode" class="form-group">
                                    <label for="inputPhoto"
                                        >Passport Photo</label
                                    >

                                    <input
                                        type="file"
                                        ref="file_upload"
                                        @change="handleFileUpload"
                                        accept="image/*"
                                        :class="{
                                            'is-invalid':
                                                form.errors.has('file'),
                                        }"
                                        class="form-control"
                                        id="inputPhoto"
                                        name="file_upload"
                                    />
                                    <has-error
                                        :form="form"
                                        field="file_upload"
                                    ></has-error>
                                </div>
                                <div v-if="form.photo_url && !promotemode && !deactivatemode" class="image text-center">
                                    <img
                                        :src="form.photo_url"
                                        width="100px"
                                        alt="The Logo"
                                        class="elevation-3"
                                        style="opacity: 0.8"
                                    />
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    v-show="editmode"
                                    type="submit"
                                    class="btn btn-success"
                                >
                                    Update
                                </button>
                                <button
                                    v-show="!editmode"
                                    type="submit"
                                    class="btn btn-primary"
                                >
                                    Create
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Meal Permission Modal -->
            <div
                class="modal fade"
                id="applyPermission"
                tabindex="-1"
                role="dialog"
                aria-labelledby="applyPermission"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">
                                Meal Permission Edit
                            </h5>                            
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <!-- <form @submit.prevent="createUser"> -->

                        <form
                            @submit.prevent="setMealPermission()"
                        >
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>Meal</label>
                                    <select
                                        name="meal_id"
                                        v-model="form4.meal_id"
                                        id="meal_id"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': form4.errors.has(
                                                'meal_id'
                                            ),
                                        }"
                                    >
                                        <option value="" disabled>
                                            Select Meal
                                        </option>
                                        <option
                                            v-for="(
                                                name, index
                                            ) in meals"
                                            :key="index"
                                            :value="index"
                                            :selected="
                                                index ==
                                                form4.meal_id
                                            "
                                        >
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error
                                        :form="form4"
                                        field="meal_id"
                                    ></has-error>
                                </div>
                                <div class="form-group">
                                    <label
                                        >Days of the Week</label
                                    >
                                    <select
                                        name="days_of_the_week"
                                        v-model="form4.days_of_the_week"
                                        id="days_of_the_week"
                                        class="selectpicker form-control"
                                        multiple
                                        title="Choose days of the week to limit permission to"                                        
                                        :class="{
                                            'is-invalid': form4.errors.has(
                                                'days_of_the_week'
                                            ),
                                        }"
                                    >
                                        <option v-for="(name,index) in week_days" :key="index" :value="name" selected>
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error
                                        :form="form4"
                                        field="days_of_the_week"
                                    ></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Allow?</label>
                                    <select
                                        name="allow"
                                        v-model="form4.allow"
                                        id="allow"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': form4.errors.has(
                                                'allow'
                                            ),
                                        }"
                                    >
                                        <option value="" disabled>
                                            Select if Allowed or Not
                                        </option>
                                        <option
                                            v-for="(
                                                name, index
                                            ) in yes_no"
                                            :key="index"
                                            :value="index"
                                        >
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error
                                        :form="form4"
                                        field="allow"
                                    ></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Permission Start Date</label>
                                    <date-picker v-model="form4.permission_applies_from_date" name="permission_applies_from_date"
                                        class="form-control" :class="{ 'is-invalid': form4.errors.has('permission_applies_from_date') }" :config="options"/>
                                    <has-error :form="form4" field="permission_applies_from_date"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Permission End Date</label>
                                    <date-picker v-model="form4.permission_applies_to_date" name="permission_applies_to_date"
                                        class="form-control" :class="{ 'is-invalid': form4.errors.has('permission_applies_to_date') }" :config="options"/>
                                    <has-error :form="form4" field="permission_applies_to_date"></has-error>
                                </div>    

                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-success"
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Perform Transaction Modal -->
            <div
                class="modal fade"
                id="performTransaction"
                tabindex="-1"
                role="dialog"
                aria-labelledby="performTransaction"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Perform Tuck Shop Transaction
                            </h5>                            
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <!-- <form @submit.prevent="createUser"> -->

                        <form
                            @submit.prevent="addTransaction()"
                        >
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>Transaction Type</label>
                                    <select
                                        name="type"
                                        v-model="form5.type"
                                        id="type"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': form5.errors.has(
                                                'type'
                                            ),
                                        }"
                                    >
                                        <option value="" disabled>
                                            Select Transaction Type
                                        </option>
                                        <option
                                            v-for="(
                                                name, index
                                            ) in add_remove"
                                            :key="index"
                                            :value="name"
                                        >
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error
                                        :form="form5"
                                        field="type"
                                    ></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Amount</label>
                                    <input v-model="form5.amount" type="number" name="amount"
                                        class="form-control" :class="{ 'is-invalid': form5.errors.has('amount') }">
                                    <has-error :form="form5" field="amount"></has-error>
                                </div>  
                                <div class="form-group">
                                    <label>Description</label>
                                    <textarea v-model="form5.description" type="text" name="description"
                                        class="form-control" :class="{ 'is-invalid': form5.errors.has('description') }">
                                    </textarea>
                                    <has-error :form="form5" field="description"></has-error>
                                </div> 
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-success"
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Show Transactions Modal -->
            <div
                class="modal fade"
                id="showTransaction"
                tabindex="-1"
                role="dialog"
                aria-labelledby="showTransaction"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {{transaction_customer.name}} Tuck Shop Transactions
                            </h5>                            
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th style="width: 10%">ID</th>
                                        <th style="width: 10%">Type</th>
                                        <th style="width: 10%">Amount</th>
                                        <th style="width: 30%">Description</th>
                                        <th style="width: 15%">Added By</th>
                                        <th style="width: 15%">Date</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        
                                            <td>
                                                <input v-model="form6.id" type="number" class="form-control form-control-sm" placeholder="ID Search"/>
                                            </td>
                                            <td>
                                                <select name="type" v-model="form6.type" class="form-control form-control-sm">
                                                    <option value="" disabled>
                                                        Select Transaction Type
                                                    </option>
                                                    <option v-for="(name, index) in add_remove" :key="index" :value="name">
                                                        {{ name }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input v-model="form6.amount" type="number" class="form-control form-control-sm" placeholder="Amount Search'"/>
                                            </td>
                                            <td>
                                                <input v-model="form6.description" type="text" class="form-control form-control-sm" placeholder="Description Search"/>
                                            </td>
                                            <td>
                                                <select v-model="form6.user_id" class="form-control form-control-sm">
                                                    <option value="" disabled>Select User</option>
                                                    <option v-for="(name, index) in users" :key="index" :value="index" :selected="index == form6.user_id">
                                                        {{ name }}
                                                    </option>
                                                </select>
                                            </td>                                            
                                            <td id="search_date_td">
                                                <date-picker v-model="form6.created_at" class="form-control form-control-sm" placeholder="Created Search" :config="options"/>
                                            </td>
                                            <td>
                                                <a
                                                    href="#"
                                                    @click="getTransactionResults()"
                                                >
                                                    <i class="fa fa-search blue"></i>
                                                </a>
                                                /
                                                <a
                                                    href="#"
                                                    @click="cancelTransactionSearch"                                                    
                                                >
                                                    <i class="fa fa-search red"></i>
                                                </a>
                                            </td>
                                    </tr>
                                    <tr
                                        v-for="tx in customer_transactions.data"
                                        :key="tx.id"
                                    >
                                        <td>{{ tx.id }}</td>
                                        <td>{{ tx.type }}</td>
                                        <td>{{ Number(tx.amount).toLocaleString() }}</td>
                                        <td>{{ tx.description }}</td>
                                        <td>{{ tx.added_by_name }}</td>
                                        <td>
                                            {{
                                                tx.created_at
                                                    | moment(
                                                        "YYYY-MM-DD HH:MM:ss"
                                                    )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination
                                :data="customer_transactions"
                                @pagination-change-page="getTransactionResults"
                            ></pagination>
                        </div>
                    </div>
                </div>
            </div>

            <!--Print Barcode Modal -->
            <div
                class="modal fade"
                id="printBarcode"
                tabindex="-1"
                role="dialog"
                aria-labelledby="printBarcode"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Print Barcode
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body justify-content-center" id="barCodesElement">
                            <div class="container">
                                <div class="row">
                                    <div class="col-sm-4 border border-primary" v-for="customerx in bar_code_customers" :key="customerx.id" style="width:25mm">
                                        <label>{{customerx.name}}</label>
                                        <barcode :value="'01'+String(customerx.id).padStart(7, '0')"  :width="2">
                                            Could Not Generate Barcode
                                        </barcode>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                @click="printBarcodeOnPaper"
                                class="btn btn-success"
                            >
                                Print
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
// Import datepicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
//bar code modal
import VueBarcode from 'vue-barcode';
export default {
    data() {
        return {
            editmode: false,
            promotemode: false,
            deactivatemode: false,
            customers: {},
            form: new Form({
                id: "",
                name: "",
                customer_no: "",
                id_no: "",
                first_level_division_id: "0",
                second_level_division_id: "0",
                photo_url:null,
                promotion_customers:[],
                deactivation_customers:[],
                active: "",
            }),
            form2: new Form({
                id: "",
                name: "",
                customer_no: "",
                id_no: "",
                first_level_division_id: "0",
                second_level_division_id: "0",
                active: "",
                created_at:"",
            }),
            form3: new Form({
                items_per_page: "10",
            }),
            form4: new Form({
                meal_id: "",
                days_of_the_week: [],
                allow:"",
                permission_applies_from_date:"",
                permission_applies_to_date:"",
                permission_customers:[],
            }),
            form5: new Form({
                type: "",
                amount: "",
                description:"",
                transaction_customers:[],
            }),
            form6: new Form({
                id: "",
                type: "",
                amount: "",
                description:"",
                user_id:"",
                created_at:""
            }),
            week_days : ["Monday","Tuesday","Wednesday","Friday","Thursday","Friday","Saturday","Sunday"],
            first_level_divisions: [],
            second_level_divisions: [],
            customer_transactions: {},
            meals: [],
            yes_no:{"1":"Yes", "0":"No"},
            add_remove:["Add","Remove"],
            customer: {},
            bar_code_customers: [],
            transaction_customer:{},
            users:[],
            options: {
                format: 'YYYY-MM-DD',
                useCurrent: false,
                widgetPositioning: {
                    horizontal: 'left',
                    vertical: 'bottom'
                },
            },
        };
    },
    components: {
      datePicker,
      'barcode': VueBarcode
    },
    methods: {
        handleFileUpload(e) {
            const file = this.$refs.file_upload.files[0];
            this.form.file = file;
        },
        getResults(page = 1) {
            this.$Progress.start();
            var search_string = "";
            var include_customer_meals_permissions = this.$route.path == '/meals_permissions' ? "&include_customer_meals_permissions=1" : "";
            if(this.form2.id != ""){
                search_string = search_string + "id=" + this.form2.id + "&";
            }
            if(this.form2.name != ""){
                search_string = search_string + "name=" + this.form2.name + "&";
            }
            if(this.form2.customer_no != ""){
                search_string = search_string + "customer_no=" + this.form2.customer_no + "&";
            }
            if(this.form2.id_no != ""){
                search_string = search_string + "id_no=" + this.form2.id_no + "&";
            }
            if(this.form2.first_level_division_id != "0"){
                search_string = search_string + "first_level_division_id=" + this.form2.first_level_division_id + "&";
            }
            if(this.form2.second_level_division_id != "0"){
                search_string = search_string + "second_level_division_id=" + this.form2.second_level_division_id + "&";
            }
            if(this.form2.active != ""){
                search_string = search_string + "active=" + this.form2.active + "&";
            }
            if(this.form2.created_at != ""){
                search_string = search_string + "created_at=" + this.form2.created_at + "&";
            }
            search_string = Buffer.from(search_string).toString('base64');
            axios
                .get("api/customer?page=" + page + "&items_per_page=" + this.form3.items_per_page +"&search_string=" + search_string + include_customer_meals_permissions)
                .then(({ data }) => (this.customers = data.data));

            this.$Progress.finish();
        },
        getTransactionResults(page = 1) {
            this.$Progress.start();
            var tx_search_string = "";
            
            if(this.form6.id != ""){
                tx_search_string = tx_search_string + "id=" + this.form6.id + "&";
            }
            if(this.form6.type != ""){
                tx_search_string = tx_search_string + "type=" + this.form6.type + "&";
            }
            if(this.form6.amount != ""){
                tx_search_string = tx_search_string + "amount=" + this.form6.amount + "&";
            }
            if(this.form6.description != ""){
                tx_search_string = tx_search_string + "description=" + this.form6.description + "&";
            }
            if(this.form6.user_id != ""){
                tx_search_string = tx_search_string + "user_id=" + this.form6.user_id + "&";
            }
            if(this.form6.created_at != ""){
                tx_search_string = tx_search_string + "created_at=" + this.form6.created_at + "&";
            }
            tx_search_string = Buffer.from(tx_search_string).toString('base64');
            axios
                .get("api/customer_tuck_shop_transactions?customer_id="+this.transaction_customer.id+"&page=" + page + "&items_per_page=10&search_string=" + tx_search_string)
                .then(({ data }) => (this.customer_transactions = data.data));

            this.$Progress.finish();
            return true;
        },
        cancelSearch(){
            this.form2.id = "";
            this.form2.name = "";
            this.form2.customer_no = "";
            this.form2.id_no = "";
            this.form2.first_level_division_id = "0";
            this.form2.second_level_division_id = "0";
            this.form2.active = "";
            this.form2.created_at = "";
            this.getResults();
        },
        cancelTransactionSearch(){
            this.form6.reset();
            this.getTransactionResults();
        },
        updateCustomer() {
            this.$Progress.start();
            // update the customer
            var formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("id", this.form.id);
            formData.append("name", this.form.name);
            formData.append("customer_no", this.form.customer_no);
            formData.append("id_no", this.form.id_no);
            formData.append(
                "first_level_division_id",
                this.form.first_level_division_id
            );
            formData.append(
                "second_level_division_id",
                this.form.second_level_division_id
            );

            if (this.form.file)
                formData.append("file", this.form.file, this.form.file.name);
            var data;
            axios({
                method: "post",
                url: "api/customer/" + this.form.id,
                data: formData,
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
                .then((response) => {
                    $("#addNew").modal("hide");
                    this.$Progress.finish();
                    Toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.getResults();
                })
                .catch((error) => {
                    this.form.errors.set(error.response.data.errors);
                    this.$Progress.fail();
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                });
        },
        promoteCustomer() {
            this.$Progress.start();
            // update the customer
            var formData = new FormData();
            formData.append(
                "first_level_division_id",
                this.form.first_level_division_id
            );
            formData.append(
                "customer_ids",
                this.form.promotion_customers
            );
            var data;
            axios({
                method: "post",
                url: "api/customer_promotion",
                data: formData,
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
                .then((response) => {
                    $("#addNew").modal("hide");
                    this.form.promotion_customers = [];
                    this.promotemode = false;
                    this.$Progress.finish();
                    Toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.getResults();
                })
                .catch((error) => {
                    this.form.errors.set(error.response.data.errors);
                    this.$Progress.fail();
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                });
        },
        deactivateCustomer() {
            this.$Progress.start();
            // deactivate the customer
            var formData = new FormData();
            formData.append(
                "active",
                this.form.active
            );
            formData.append(
                "customer_ids",
                this.form.deactivation_customers
            );
            var data;
            axios({
                method: "post",
                url: "api/customer_deactivation",
                data: formData,
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
                .then((response) => {
                    $("#addNew").modal("hide");
                    this.form.deactivation_customers = [];
                    this.deactivatemode = false;
                    this.$Progress.finish();
                    Toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.getResults();
                })
                .catch((error) => {
                    this.form.errors.set(error.response.data.errors);
                    this.$Progress.fail();
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                });
        },
        setMealPermission() {
            this.$Progress.start();
            this.form4.post('api/meal_permission')
              .then((data)=>{
                if(data.data.success){
                  $('#applyPermission').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.form4.reset();
                  this.getResults();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
        },
        addTransaction() {
            this.$Progress.start();
            this.form5.post('api/add_customer_transaction')
              .then((data)=>{
                if(data.data.success){
                  $('#performTransaction').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.form5.reset();
                  this.getResults();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
        },
        editModal(customer) {
            this.editmode = true;
            this.promotemode = false;
            this.deactivatemode = false;
            this.form.reset();
            this.form.errors.set({});
            this.$refs.file_upload.value = null;
            $("#addNew").modal("show");
            this.form.fill(customer);
        },
        printModal(customer) {
            this.bar_code_customers = [];
            this.bar_code_customers.push(customer);
            $("#printBarcode").modal("show");
        },
        printAllModal() {
            this.bar_code_customers = [];
            this.bar_code_customers = this.customers.data;
            $("#printBarcode").modal("show");
        },
        printBarcodeOnPaper() {
            const divName = "barCodesElement"
            var printContents = document.getElementById(divName).innerHTML;
            $("#printBarcode").modal("hide");
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            this.$router.go()

            //document.body.innerHTML = originalContents;

            //return true;
        },
        promoteModal(customer) {
            this.editmode = true;
            this.promotemode = true; 
            this.deactivatemode = false;        
            this.form.reset();
            this.form.promotion_customers = [];
            this.form.promotion_customers.push(customer.id); 
            this.form.errors.set({});
            $("#addNew").modal("show");
        },
        promoteAllModal() {
            this.editmode = true;
            this.promotemode = true;      
            this.deactivatemode = false;    
            this.form.reset();
            this.form.promotion_customers = [];
            this.form.promotion_customers = this.customers.data.map((customer) => customer.id);
            this.form.errors.set({});
            $("#addNew").modal("show");
        },
        deactivateModal(customer) {
            this.editmode = true;
            this.promotemode = false; 
            this.deactivatemode = true;        
            this.form.reset();
            this.form.deactivation_customers = [];
            this.form.deactivation_customers.push(customer.id); 
            this.form.errors.set({});
            $("#addNew").modal("show");
        },
        deactivateAllModal() {
            this.editmode = true;
            this.promotemode = false;      
            this.deactivatemode = true;    
            this.form.reset();
            this.form.deactivation_customers = [];
            this.form.deactivation_customers = this.customers.data.map((customer) => customer.id);
            this.form.errors.set({});
            $("#addNew").modal("show");
        },
        applyPermissionModal(customer) {     
            this.form4.reset();
            this.form4.meal_id = "";
            this.form4.days_of_the_week = [];
            this.form4.allow = "";
            this.form4.permission_applies_from_date = '';
            this.form4.permission_applies_to_date = "";
            this.form4.permission_customers = [];
            this.form4.permission_customers.push(customer.id); 
            this.form4.errors.set({});
            $("#applyPermission").modal("show");
        },
        applyPermissionToAllModal() {
            this.form4.reset();
            this.form4.meal_id = "";
            this.form4.days_of_the_week = [];
            this.form4.allow = "";
            this.form4.permission_applies_from_date = '';
            this.form4.permission_applies_to_date = "";
            this.form4.permission_customers = [];
            this.form4.permission_customers = this.customers.data.map((customer) => customer.id);
            this.form4.errors.set({});
            $("#applyPermission").modal("show");
        },
        performTransactionModal(customer) {     
            this.form5.reset();
            this.form5.type = "";
            this.form5.amount = "";
            this.form5.description = "";
            this.form5.transaction_customers = [];
            this.form5.transaction_customers.push(customer.id); 
            this.form5.errors.set({});
            $("#performTransaction").modal("show");
        },
        performTransactionOnAllModal() {
            this.form5.reset();
            this.form5.type = "";
            this.form5.amount = "";
            this.form5.description = "";
            this.form5.transaction_customers = [];
            this.form5.transaction_customers = this.customers.data.map((customer) => customer.id);
            this.form5.errors.set({});
            $("#performTransaction").modal("show");
        },
        async showTransactionsModal(customer,page=1) {     
            this.transaction_customer = customer; 
            this.$Progress.start();
            this.getTransactionResults();
            this.loadUsers();
            $("#showTransaction").modal("show");
            this.$Progress.finish();                   
        },
        newModal() {
            this.editmode = false;
            this.promotemode = false;
            this.form.reset();
            this.form.errors.set({});
            $("#addNew").modal("show");
        },
        deleteCustomer(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.form
                        .delete("api/customer/" + id)
                        .then(() => {
                            Swal.fire(
                                "Deleted!",
                                "Your file has been deleted.",
                                "success"
                            );
                            // Fire.$emit('AfterCreate');
                            this.loadCustomers();
                        })
                        .catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                }
            });
        },
        loadCustomers() {
            this.$Progress.start();

            if (this.$gate.isSuperAdmin()) {
                axios
                    .get("api/customer")
                    .then(({ data }) => (this.customers = data.data));
            }

            this.$Progress.finish();
        },
        loadFlds() {
            axios
                .get("/api/first_level_divisions/list")
                .then(({ data }) => (this.first_level_divisions = data.data));
        },
        loadSlds() {
            axios
                .get("/api/second_level_divisions/list")
                .then(({ data }) => (this.second_level_divisions = data.data));
        },
        loadMeals() {
            axios
                .get("/api/meal/list")
                .then(({ data }) => (this.meals = data.data));
        },
        loadUsers() {
            axios
                .get("/api/user/list")
                .then(({ data }) => (this.users = data.data));
        },
        createCustomer() {
            var formData = new FormData();
            formData.append("id", this.form.id);
            formData.append("name", this.form.name);
            formData.append("customer_no", this.form.customer_no);
            formData.append("id_no", this.form.id_no);
            formData.append(
                "first_level_division_id",
                this.form.first_level_division_id
            );
            formData.append(
                "second_level_division_id",
                this.form.second_level_division_id
            );

            if (this.form.file)
                formData.append("file", this.form.file, this.form.file.name);
            
            var data;
            axios({
                method: "POST",
                url: "api/customer",
                data: formData,
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
                .then((response) => {
                    $("#addNew").modal("hide");
                    this.$Progress.finish();
                    Toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.loadCustomers();
                })
                .catch((error) => {
                    this.form.errors.set(error.response.data.errors);
                    this.$Progress.fail();
                    Toast.fire({
                        icon: "error",
                        title: "Some error occured! Please try again",
                    });
                });
        },
    },

    mounted() {
        console.log("Customer Component mounted.");
    },
    created() {
        this.$Progress.start();
        this.getResults();
        this.organization_type = window.user.organization_type;
        this.loadFlds();
        this.loadSlds();
        this.loadMeals();
        this.$Progress.finish();
    },
};
</script>
